import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueWechatTitle from 'vue-wechat-title' //统一路由
import waterfall from 'vue-waterfall2' //瀑布流图片显示
import vueAplayer from 'vue-aplayer' //音乐播放器
import VueCropper from 'vue-cropper' //图片裁剪组件
import Loading from '@/components/loading/loading.js'//Loading
//vue-quill-editor富文本
import  VueQuillEditor  from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import dayjs from "dayjs"
Vue.prototype.dayjs = dayjs;//可以全局使用dayjs
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
import echartsGL from 'echarts-gl' // 引入echarts
Vue.prototype.$echartsGL = echartsGL // 引入组件（将echarts注册为全局）
import 'echarts-liquidfill/src/liquidFill.js'; //在这里引入
//使用百度地图
import BaiduMap from 'vue-baidu-map-v3'
Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: 'gLWV67xpvVPwlwdRqg5BHqFmXVmFsB0h'
})

Vue.directive('title', {//单个修改标题
    inserted: function (el, binding) {
        document.title = el.dataset.title
    }
})

//自动滚动
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

NProgress.configure({
  showSpinner: false,
  minimum: 0.1,
})

router.beforeEach((to, from, next) => {
    const token = store.getters.getToken

    next()
});

Vue.use(ElementUI);
Vue.use(ElImageViewer);
Vue.use(VueWechatTitle)
Vue.use(waterfall)
Vue.use(vueAplayer)
Vue.use(VueCropper)
Vue.use(VueQuillEditor)
Vue.use(VideoPlayer)
Vue.use(Loading)
Vue.prototype.$axios = axios;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
