<template >
<!--  <scale-box v-if="windowWidth != 0" :width="1920" :height="937" bgc="transparent" :delay="100">-->
    <router-view />
<!--  </scale-box>-->
</template>

<script>
import ScaleBox from "vue2-scale-box";
import devPixelRatio from "@/utils/devicePixelRatio.js";
  export default {
    data() {
      return {

      }
    },
    components: {
      ScaleBox
    },
    created() {
      // new devPixelRatio().init();
    },
    mounted() {

    },
    methods:{

    }
  }
</script>
<style>
  /*把最外面的html，body设置为100%就好*/
  html,
  #app,
  body {
    overflow: hidden;
    height: 100%;
    min-width: 9rem;
    margin: 0;
    padding: 0;
  }
  ::-webkit-scrollbar {
    width: 0.07rem;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0.14rem;
    -webkit-box-shadow: inset 0 0 0.05rem rgba(0, 0, 0, 0.2);
    background: #dddee0;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.05rem rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: none;
  }
  .ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ellipsisTwo{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .ellipsisThree{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
</style>
