import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    icon: "",
    component: () => import("../views/elevator/ceViewCopyTadiao.vue"),
  },
  {
    path: "/indexnew",
    name: "Home",
    icon: "",
    component: () => import("../views/Home/index"),
  },
  {
    path: "/publicHouse",
    name: "Home",
    icon: "",
    component: () => import("../views/Home/publicHouse"),
  },
  {
    path: "/enterprise",
    name: "enterprise",
    icon: "",
    component: () => import("../views/enterprise/indexnewbeifen"),
  },
  {
    path: "/enterprisetiqi",
    name: "enterprise",
    icon: "",
    component: () => import("../views/enterprise/indexnewtiqi"),
  },
  {
    path: "/enterprisenew",
    name: "enterprise",
    icon: "",
    component: () => import("../views/enterprise/indexnew"),
    // component: () => import("../views/enterprise/indexnew"),
  },
  {
    path: "/ceViewCopyTadiao",
    name: "enterprise",
    icon: "",
    component: () => import("../views/elevator/ceViewCopyTadiao.vue"),
  },
  {
    path: "/towerCrane",
    name: "enterprise",
    icon: "",
    component: () => import("../views/towerCrane"),
  },
  {
    path: "/ceViewCopyqiti",
    name: "enterprise",
    icon: "",
    component: () => import("../views/elevator/ceViewCopyqiti.vue"),
  },
  {
    path: "/lift",
    name: "lift",
    icon: "",
    component: () => import("../views/lift"),
  },
  {
    path: "/hacb",
    name: "lift",
    icon: "",
    component: () => import("../views/hacb"),
  },
  {
    path: "/gaotang",
    name: "lift",
    icon: "",
    component: () => import("../views/gaotang"),
  },
  {
    path: "/hacb",
    name: "lift",
    icon: "",
    component: () => import("../views/hacb"),
  },
  {
    path: "/wxzy",
    name: "lift",
    icon: "",
    component: () => import("../views/hacb/indexWXZY"),
  },
  {
    path: "/marineEquipment",
    name: "lift",
    icon: "",
    component: () => import("../views/marineEquipment"),
  },
  {
    path: "/elevatorCity",
    name: "lift",
    icon: "",
    component: () => import("../views/elevator/cityNew"),
  },
  {
    path: "/ceView",
    name: "lift",
    icon: "",
    component: () => import("../views/elevator/ceView"),
  },
  {
    path: "/ceViewCopy",
    name: "lift",
    icon: "",
    component: () => import("../views/elevator/ceViewCopy"),
  },
  {
    path: "/elevatorZone",
    name: "lift",
    icon: "",
    component: () => import("../views/elevator/zone"),
  },
  {
    path: "/ce",
    name: "lift",
    icon: "",
    component: () => import("../views/constructionEngineering"),
  },
  {
    path: "/elevatorUnderConstruction",
    name: "lift",
    icon: "",
    component: () => import("../views/elevator/underConstruction"),
  },
  {
    path: "/unknown",
    name: "lift",
    icon: "",
    component: () => import("../views/unknown"),
  },
  {
    path: "/dmph",
    name: "lift",
    icon: "",
    component: () => import("../views/dmph"),
  },
  {
    path: "*",
    name: "404",
    component: () => import("../components/404"),
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.afterEach(route => {
  // 从路由的元信息中获取 title 属性
  if (route.meta.title) {
    document.title = route.meta.title;
    // 如果是 iOS 设备，则使用如下 hack 的写法实现页面标题的更新
    if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      const hackIframe = document.createElement('iframe');
      hackIframe.style.display = 'none';
      hackIframe.src = '/static/html/fixIosTitle.html?r=' + Math.random();
      document.body.appendChild(hackIframe);
      setTimeout(_ => {
        document.body.removeChild(hackIframe)
      }, 300)
    }
  }
});

const originPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originPush.call(this, location).catch(err => err)
}

//获取开始时间
let startTime = Date.now();
//定义一个获取用户的函数
const get_userinfo = (to, from, token) => {
//获取当前时间
  let currentTime = Date.now();
  let Brow_history = {
    preUrl: from.path,
    preTitle: from.meta.title,
    currentUrl: to.path,
    currentTitle: to.meta.title,
    prePage_browTime: parseInt((currentTime - startTime) / 1000) + "s",
  };
  if(token){
    Brow_history.user = JSON.parse(sessionStorage.getItem("user"));
  }
  // console.log(Brow_history);
  // console.log(
  //   "用户由 ",
  //   from.path,
  //   " 页面 跳转到 ",
  //   to.path,
  //   " 页面，在 ",
  //   from.path,
  //   " 页面停留了 ",
  //   currentTime - startTime,
  //   "毫秒。转换成秒数约为：",
  //   parseInt((currentTime - startTime) / 1000)
  // );
  //初始化开始时间
  startTime = Date.now();
  // console.log("======== 分割线 ========");
};

router.beforeEach((to, from, next) => {
  // get_userinfo(to, from);
  // next();
  // NProgress.start();
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title;
  }
  if (to.meta.requireAuth == true) {
    const token = getToken();
    //需要登录权限进入的路由
    if (token) {
    //调用刚才定义的函数
      get_userinfo(to, from, token);
      next();
    } else {
      Message.error("未登录");
      next({ name: "login" });
    }
  } else {
    get_userinfo(to, from);
    //不需要登录权限
    next();
  }
  next();
});


export default router
